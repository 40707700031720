import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  Card,
  CardBody,
  CloseButton,
  Col,
  Container,
  Input,
  Modal,
  Row,
  Spinner,
} from "reactstrap";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import "./exam.css";
// Breadcrumb

import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { userData } from "../../store/getProfileData";
import ExamListTable from "./ExamTable/ExamTableList";
import { Slider, Toggle } from "rsuite";
import ExportFile from "../Units/UnitTable/export";
// import CourseListTable from "../CourseTable/courseListTable";

const Exam = () => {
  document.title = "Courses | Matary ";

  //

  const navigate = useNavigate();

  const [examdata, setexamdata] = useState({
    exam_name: "", 
    timer: "",
    end_date: "",
    start_date: "",
  });
  const [courses, setcourses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assignexamshow, setassignexamshow] = useState(false);
  const [addloading, setaddloading] = useState(false);
  const [exams, setexams] = useState([]);
  const [endDate, setEndDate] = useState(null);
  const [time_for, setTimeFor] = useState(false);
  const [startDate, setStartDate] = useState(null);
  // setStartDate
  const [selectedexam, setselectedexam] = useState("");
  const [ExamdataNewTime, setexamdataNewTime] = useState("");

  const [coursesassined, setcoursesassined] = useState([]);

  const [assignloading, setassignloading] = useState(false);
  let [answerNumber, setAnswrsNumber] = useState(1);
  const [answers, setAnswers] = useState([]);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [timerChecked, setChecked] = useState(false);
  const [deadLineChecked, setDeadLineChecked] = useState(false);
  // deadLineChecked
  const [checkedData, setCheckedData] = useState({
    open: false,
    loading: false,
    data: [],
    exam_id: null,
  });
  const checkExams = (examData) => {
    setCheckedData((prev) => ({
      ...prev,
      open: true,
      loading: true,
      exam_id: examData,
    }));
    const data_send = {
      exam_id: examData?.exam_id,
    };

    axios
      .post(
        "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/Exams/check_exam_qus.php",
        {
          ...data_send,
        }
      )
      .then((res) => {
        setCheckedData((prev) => ({
          ...prev,
          open: true,
          loading: true,
          data: res?.message,
        }));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setCheckedData((prev) => ({
          ...prev,
          loading: false,
        }));
      });
  };
  const getExams = () => {
    const data_send = {
      course_id: "1",
    };

    axios
      .get(
        "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/Exams/select_all_exams.php"
      )
      .then((res) => {
        setexams(res.message);
        setselectedexam(res?.message[0]?.exam_id);
      })
      .catch((err) => console.log(err));
  };

  const getcourses = () => {
    axios
      .get(
        "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/courses/select_courses.php"
      )
      .then((res) => {
        setcourses(res);
        setexamdata({ ...examdata, course_id: res[0].course_id });
      });
  };

  const handleaddexam = () => {
    setaddloading(true);
    const datasend = {
      ...examdata,
      rules: answers?.map((item) => item?.answer)?.join("**camp**"),
      time_for: !time_for ? "qus" : "exam",
      course_id: "1",
    };

    axios
      .post(
        "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/Exams/insert_exam.php",
        datasend
      )
      .then((res) => {
        console.log("res", res);
        if (res.status == "success") {
          toast.success("exam has added successfully");
          setIsModalOpen(false);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("SomeThing Went Error");
        }
        getExams();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setaddloading(false);
      });
  };

  const formatDate = (dateTime) => {
    const date = new Date(dateTime);
    return moment(date).format("YYYY-MM-DD HH:MM:SS");
  };

  const hanldeassign = () => {
    setassignloading(true);

    const coursesdata = [...coursesassined];
    let courses_data = "";
    for (let i = 0; i < coursesdata.length; i++) {
      if (i == 0) {
        courses_data += coursesdata[i].value;
      } else {
        courses_data += "***matary***" + coursesdata[i].value;
      }
    }
    const data_send = {
      exam_id: selectedexam,
      courses_data,
      timer: ExamdataNewTime,
      end_date: formatDate(endDate),
      start_date: formatDate(startDate),
    };
    axios
      .post(
        "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/Exams/assign_exam_to_course.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        if (res.status == "success") {
          toast.success(res.message);
          setassignexamshow(false);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setassignloading(false);
      });
  };

  useEffect(() => {
    getcourses();
    getExams();
  }, []);
  const permissions = userData?.permissions;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Exams" breadcrumbItem="Exam List" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="position-relative">
                    <div className="modal-button mt-2">
                      <Row className="align-items-start">
                        <Col className="col-sm">
                          {permissions?.includes("*20_1") ||
                          permissions?.startsWith("20_1") ||
                          permissions == "all" ? (
                            <div>
                              <button
                                type="button"
                                className="btn btn-success mb-4"
                                data-bs-toggle="modal"
                                data-bs-target="#addCourseModal"
                                onClick={() => {
                                  showModal();
                                }}
                              >
                                <i className="mdi mdi-plus me-1"></i> Add Exam
                              </button>
                            </div>
                          ) : null}
                        </Col>
                        <Col className="col-sm">
                          <div>
                            {permissions?.includes("*20_3") ||
                            permissions?.startsWith("20_3") ||
                            permissions == "all" ? (
                              <button
                                type="button"
                                className="btn btn-success mb-4"
                                data-bs-toggle="modal"
                                data-bs-target="#addCourseModal"
                                onClick={() => {
                                  // showModal();
                                  setassignexamshow(true);
                                }}
                              >
                                Assign Exam
                              </button>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div id="table-invoices-list">
                    <ExamListTable exams={exams} checkExams={checkExams} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal title="add exam" isOpen={isModalOpen}>
          <form
            action="#"
            style={{
              padding: "15px",
              display: "flex",
              flexDirection: "column",
              gap: "13px",
            }}
            onSubmit={(e) => {
              e.preventDefault();
              handleaddexam();
            }}
          >
            <CloseButton
              onClick={() => setIsModalOpen(false)}
              style={{ marginLeft: "auto" }}
            />

            <div className="inputField withtext">
              <label htmlFor="exam_name">Exam Name</label>
              <Input
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "4px",
                }}
                type="text"
                name="exam_name"
                id="exam_name"
                placeholder="exam name"
                required
                onChange={(e) => {
                  setexamdata({ ...examdata, exam_name: e.target.value });
                }}
              />
            </div>
            <div className="mb-3">
              <div className="answerControl">
                <label className="form-label">Rules</label>
                <span
                  className="btn btn-success"
                  onClick={() => {
                    setAnswrsNumber(++answerNumber);
                  }}
                >
                  Add New Rule
                </span>
              </div>
              {Array(answerNumber)
                ?.fill(0)
                ?.map((item, index) => {
                  return (
                    <div className="answerContainer">
                      <Input
                        name="book_title"
                        type="text"
                        onChange={(e) =>
                          answers?.filter((item) => item?.id == index)?.length
                            ? setAnswers(
                                answers?.map((item) => {
                                  if (item?.id == index) {
                                    item.answer = e?.target?.value;
                                  }
                                  return item;
                                })
                              )
                            : setAnswers([
                                ...answers,
                                { id: index, answer: e.target?.value },
                              ])
                        }
                      />
                      <span
                        className="btn btn-primary"
                        onClick={() => {
                          setAnswrsNumber(--answerNumber);
                          setAnswers(
                            answers?.filter((item) => item.id != index)
                          );
                        }}
                      >
                        delete
                      </span>
                    </div>
                  );
                })}
            </div>
            <div className="mb-3">
              <div className="answerControl">
                <label className="form-label">Covered Concept</label>
              </div>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                style={{ width: "100%" }}
                onChange={(e) => {
                  setexamdata({ ...examdata, exam_parts: e.target.value });
                }}
              ></textarea>
            </div>
            <div className="">
              <label htmlFor="time">Time For</label>
              <div className="d-flex">
                <span> Question</span> {"   "}
                <div>
                  <Toggle
                    arial-label="Switch"
                    value={time_for}
                    onChange={(e) => setTimeFor(e)}
                  />
                </div>
                {"    "}
                <span> Exam</span>
              </div>
            </div>

            {/* <div className="inputField withtext">
              <label htmlFor="exam_details">courses</label>
              <select value={examdata.course_id} onChange={(e)=>{
                setexamdata({...examdata,course_id:e.target.value})
              }} className="form-select" name="" id="">
                {
                  courses.map((item)=>{
                    return (
                      <option value={item.course_id}>{item.course_name}</option>
                    )
                  })
                }
              </select>
            </div> */}
            {/* <div className="inputField withtext">
              <label htmlFor="exam_details">Exam Details</label>
              <textarea
                style={{ height: "100px" }}
                id="hours"
                name="explanation"
                placeholder="Enter Explanation"
                type="number"
                className="form-control"
              ></textarea>
            </div> */}
            {/* <div className="inputField withtext">
              <label htmlFor="exam_degree">Exam Degree</label>
              <Input
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "4px",
                }}
                type="text"
                name="exam_degree"
                id="exam_degree"
                placeholder="exam_degree"
                required
              />
            </div> */}
            <div className="checkedBoxes">
              <div className="inputField">
                <label
                  className={timerChecked ? "active" : null}
                  htmlFor="exam_istimed"
                >
                  {" "}
                  With timer ?
                </label>
                <Input
                  style={{
                    padding: "10px",
                    borderRadius: "4px",
                    display: "none",
                  }}
                  type="checkbox"
                  name="exam_istimed"
                  id="exam_istimed"
                  placeholder="exam_istimed"
                  required
                  checked={timerChecked}
                  onChange={(e) => setChecked(e.currentTarget.checked)}
                />
                {timerChecked ? (
                  <div className="inputField withtext">
                    {!time_for ? (
                      <label htmlFor="time">Seconds</label>
                    ) : (
                      <label htmlFor="time">Minutes</label>
                    )}

                    <Slider
                      min={0}
                      max={200}
                      progress
                      style={{ marginTop: 16 }}
                      onChange={(value) => {
                        setexamdata({ ...examdata, timer: value });
                      }}
                    />
                  </div>
                ) : null}
              </div>

              <div className="inputField">
                <label
                  className={deadLineChecked ? "active" : null}
                  htmlFor="exam_isDeadLined"
                >
                  {" "}
                  start date ?
                </label>
                <Input
                  style={{
                    padding: "10px",
                    borderRadius: "4px",

                    display: "none",
                  }}
                  type="checkbox"
                  name="exam_isDeadLined"
                  id="exam_isDeadLined"
                  placeholder="exam_isDeadLined"
                  required
                  checked={deadLineChecked}
                  onChange={(e) => setDeadLineChecked(e.currentTarget.checked)}
                />
                {deadLineChecked ? (
                  <div className="inputField withtext">
                    <label htmlFor="time">Start Date</label>
                    <Input
                      onChange={(e) => {
                        //
                        setexamdata({
                          ...examdata,
                          start_date: moment(e.target.value).format(
                            "Y-M-D H:m:s"
                          ),
                        });
                      }}
                      type="date"
                    />
                  </div>
                ) : null}
              </div>
              <div className="inputField">
                <label
                  className={deadLineChecked ? "active" : null}
                  htmlFor="exam_end"
                >
                  {" "}
                  end date ?
                </label>
                <Input
                  style={{
                    padding: "10px",
                    borderRadius: "4px",

                    display: "none",
                  }}
                  type="checkbox"
                  name="exam_end"
                  id="exam_end"
                  placeholder="end exam"
                  required
                  checked={deadLineChecked}
                  // onChange={(e) => setDeadLineChecked(e.currentTarget.checked)}
                />
                {deadLineChecked ? (
                  <div className="inputField withtext">
                    <label htmlFor="time">end Date</label>
                    <Input
                      onChange={(e) => {
                        //
                        //
                        setexamdata({
                          ...examdata,
                          end_date: moment(e.target.value).format(
                            "Y-M-D H:m:s"
                          ),
                        });
                      }}
                      type="date"
                    />
                  </div>
                ) : null}
              </div>
            </div>
            {addloading ? (
              <div
                style={{
                  textAlign: "end",
                }}
              >
                <Spinner style={{ color: "blue" }} />
              </div>
            ) : (
              <button
                className="btn btn-success"
                style={{ margin: "10px 0 0 auto" }}
              >
                {" "}
                Add Exam{" "}
              </button>
            )}
          </form>
        </Modal>

        <Modal title="assign exam" isOpen={assignexamshow}>
          <form
            action="#"
            style={{
              padding: "15px",
              display: "flex",
              flexDirection: "column",
              gap: "13px",
            }}
            onSubmit={(e) => {
              e.preventDefault();
              hanldeassign();
              // setassignexamshow(false);
            }}
          >
            <CloseButton
              onClick={() => setassignexamshow(false)}
              style={{ marginLeft: "auto" }}
            />

            <div className="inputField withtext">
              <label htmlFor="exam_name">Exam Name</label>

              <select
                onChange={(e) => {
                  // set
                  setselectedexam(e.target.value);
                }}
                value={selectedexam}
                className="form-control"
                name=""
                id=""
              >
                {exams && exams.length
                  ? exams.map((item) => {
                      return (
                        <option value={item.exam_id}>{item.exam_name}</option>
                      );
                    })
                  : null}
              </select>
            </div>

            <div className="inputField withtext">
              <label htmlFor="time">Minutes</label>
              <Slider
                min={0}
                max={200}
                onChange={(e) => {
                  //
                  setexamdataNewTime(e);
                }}
              />
            </div>

            <div className="inputField withtext">
              <label htmlFor="time">Start Date</label>
              <input
                type="date"
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="inputField withtext">
              <label htmlFor="time">End Date</label>
              <input type="date" onChange={(e) => setEndDate(e.target.value)} />
            </div>
            <div className="inputField withtext">
              <label htmlFor="exam_name">Course</label>
              <Select
                onChange={(e) => {
                  setcoursesassined(e);
                }}
                // defaultValue={[...courses]}
                isMulti
                name="colors"
                options={courses.map((item) => {
                  //
                  return {
                    label: `${item.course_name}(${item.university_name} / ${item.grade_name})`,
                    value: item.course_id,
                  };
                })}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
            <div style={{ marginTop: "30px", textAlign: "end" }}>
              {assignloading ? (
                <Spinner style={{ color: "blue" }} />
              ) : (
                <button className="btn btn-success">assign</button>
              )}
            </div>
          </form>
        </Modal>

        <Modal title="Check Exam" isOpen={checkedData?.open}>
          <form
            action="#"
            style={{
              padding: "15px",
              display: "flex",
              flexDirection: "column",
              gap: "13px",
            }}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <CloseButton
              onClick={() =>
                setCheckedData({
                  open: false,
                  loading: false,
                  data: [],
                  exam_id: null,
                })
              }
              style={{ marginLeft: "auto" }}
            />

            {checkedData?.loading ? (
              <Spinner style={{ color: "blue" }} />
            ) : (
              <table className="custom-table">
                <thead>
                  <tr>
                    <th>Question No.</th>
                    <th>Checked</th>
                  </tr>
                </thead>
                <tbody>
                  {checkedData?.data && checkedData?.data.length ? (
                    checkedData.data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.no}</td>
                        <td className="check-symbol">
                          {item.check ? (
                            <span className="check-true">✔</span>
                          ) : (
                            <span className="check-false">✘</span>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2" className="no-questions">
                        No Questions Checked
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </form>
        </Modal>
      </div>

      <ToastContainer />
    </React.Fragment>
  );
};

export default Exam;
