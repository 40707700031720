import React from "react";

import { Container } from "reactstrap";

import { userData } from "../../store/getProfileData";

const Dashboard = () => {
  document.title = "Dashboard | Matary";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true} className="home-page-content">
          <img
            src="https://res.cloudinary.com/duovxefh6/image/upload/v1705311791/Logo_set-04_1_kqlnem.svg"
            alt=""
          />
          <div className="welocme">
            {" "}
            <span>Welcome Dr.</span>
            <p style={{ margin: 0 }}>{userData?.user_name}..!</p>
          </div>
          <div className="server-type text-danger">
          - Server 1 -
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
