import axios from "axios";

export const uploadImage = async (file) => {
  const formData = new FormData();
  formData.append("image", file);
  const url = await axios.post(
    "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/image_uplouder.php",
    formData
  );
  return url;
};
