import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Modal,
  TabContent,
  TabPane,
  Tooltip,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ModalHeader,
  ModalBody,
  Input,
  CloseButton,
} from "reactstrap";

// Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

// Breadcrumb
// import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import CourseListTable from "./CourseTable/courseListTable";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import CourseListTable from "../Courses/CoursesList/CourseTable/courseListTable";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import UniversityListTable from "./UniversityTable/UniversityListTable";
import { Loader } from "rsuite";
import { userData } from "../../store/getProfileData";
// import { CloseButton } from "react-toastify/dist/components";

const PollUniversities = () => {
  document.title = "Courses | Matary ";
  const navigate = useNavigate();

  const [Univerisities, setUniverisities] = useState(false);

  const [showadduni, setshowadduni] = useState(false);
  const [itemLoader, setItemLoader] = useState(false);
  const getUniversities = async () => {
    setItemLoader(true);
    const University = await axios.get(
      "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/universities/select_university.php"
    );
    // console.log(University);
    setUniverisities(
      userData?.university_id != 0
        ? [...University.message].filter(
            (item) => item?.university_id == userData?.university_id
          )
        : [...University.message]
    );

    setItemLoader(false);
  };

  const showHideCourse = async (send_data) => {
    const courses = await axios.post(
      "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/courses/show_hide_course.php",
      JSON.stringify(send_data)
    );
    //
    if (courses.status) {
      toast.success(courses.message);
      await getUniversities();
    } else {
      toast.error(courses.message);
    }
  };

  const [university_name, setuniversity_name] = useState("");

  const handleaddnewuni = () => {
    if (university_name == "" || university_name == null) {
      toast.warn("Enter University Name");
      return;
    }
    const data_send = {
      university_name,
    };

    axios
      .post(
        "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/universities/insert_university.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        if (res.status == 'success') {
          window.location.reload();
          toast.success(res.message);
        } else if (res.status == 'error') {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUniversities();
  }, []);
  // const permissions = userData?.permissions;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Universitys" breadcrumbItem="University List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div id="table-invoices-list">
                    {itemLoader ? (
                      <Loader />
                    ) : (
                      <>
                        <UniversityListTable
                          handleupdateparent={() => {
                            getUniversities();
                          }}
                          Univerisities={Univerisities}
                          showHideCourse={showHideCourse}
                        />{" "}
                        : <div>{/* <h2>No Univs</h2> */}</div>
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={showadduni}>
            <ModalHeader tag="h4">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <h4>Add New University</h4>
                <CloseButton
                  onClick={() => {
                    setshowadduni(false);
                  }}
                  style={{ marginLeft: "auto" }}
                />
              </div>
            </ModalHeader>
            <ModalBody>
              <form
                style={{
                  padding: "15px",
                  display: "flex",
                  flexDirection: "column",
                }}
                onSubmit={(e) => {
                  e.preventDefault();
                  // AssignVideo(e)
                  handleaddnewuni();
                }}
              >
                <div className="input_Field">
                  <label htmlFor="">University Name</label>
                  <Input
                    onChange={(e) => {
                      setuniversity_name(e.target.value);
                    }}
                    style={{
                      width: "100%",
                      borderRadius: "4px",
                      margin: "10px 0",
                    }}
                    type="text"
                    name="new_title"
                    id="new_title"
                    placeholder="Enter University Name"
                  />
                </div>
                <button
                  className="btn btn-success"
                  style={{ margin: "10px 0 0 auto" }}
                >
                  {" "}
                  Add{" "}
                </button>
              </form>
            </ModalBody>
          </Modal>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  );
};

export default PollUniversities;
